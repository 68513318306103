import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    handleRegisterSubmit,
    handleSignInSubmit,
} from "../helper/webAuthnHelper";
import Successful from "../components/Successful";
import Loading from "../components/Loading";
import { useLocation } from "react-router-dom";
const PasswordLess = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [err, setErr] = useState(false);
    const [registration, setRegistration] = useState(false);
    const [authData, setAuthData] = useState({
        organizationId: "",
        token: "",
        // isRegistered: "False",
        isLoaded: false,
        action: "",
        email: "",
        signature:""
    });

    const [witwayToken, setWitWayToken] = useState<any>();
    const setWitwayToken = (e: any) => {
        setWitWayToken(e);
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Get the 'email' query parameter
    const userEmail = queryParams.get("email");
    const action = queryParams.get("action");
    //const witwaySignature = queryParams.get("witwaySignature");

    const handleRegistration = (e) => {
        setRegistration(e);
    };
    // register
    const register = async () => {
        setIsLoading(true);
        if (authData.organizationId && authData.token) {
            await handleRegisterSubmit(
                authData.organizationId,
                authData.token,
                (e: boolean) => handleRegistration(e)
            );
        }
        setIsLoading(false);
    };
    // end register
    // signin
    const signin = async () => {
        setIsLoading(true);
        if (authData.organizationId && authData.token) {
            await handleSignInSubmit(
                authData.organizationId,
                authData.token,
                (e: any) => setWitwayToken(e)
            );
        }
        setIsLoading(false);
    };
    // end signin

    // get initial auth data
    const getAuthData = async () => {
        try {
            await axios.get(`/account/authdata/${userEmail}`).then(async (res) => {
                if (res.status !== 200) {
                    setErr(true);
                }
                setAuthData((prev) => ({ ...prev, ...res.data, isLoaded: true }));
            });
        } catch (error) {
            // console.log("error from auth data", error);
        }
    };
    const authenticationAction = () => {
        switch (authData.action) {
            case "signin":
            case "reply_guard":
                signin();
                break;
            case "register":
                register();
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        const loadOfficeJS = () => {
            return new Promise((resolve, reject) => {
                if (window.Office) {
                    resolve(true);
                    return;
                }
                const script = document.createElement("script");
                script.src = "https://appsforoffice.microsoft.com/lib/1/hosted/office.js";
                script.async = true;
                script.onload = () => {
                    if (window.Office) {
                        resolve(true);
                    } else {
                        reject(new Error("Office.js did not load correctly."));
                    }
                };
                script.onerror = () => reject(new Error("Failed to load Office.js."));
                document.head.appendChild(script);
            });
        };

        loadOfficeJS()
            .then(() => {
                Office.onReady(() => {
                    console.log("Office.js initialized");
                    // Notify parent that the add-in is ready
                    window.Office.context.ui.messageParent("ready");

                    // Set up a handler for messages from the parent
                    window.Office.context.ui.addHandlerAsync(
                        window.Office.EventType.DialogParentMessageReceived,
                        (eventArgs) => {
                            try {
                                const data = JSON.parse(eventArgs.message);
                                console.log("Received data from parent:", data);
                                setAuthData((prevData) => ({
                                    ...prevData, ...data, isLoaded: true
                                }));
                            } catch (error) {
                                console.error("Error parsing parent message:", error);
                            }
                        }
                    );

                    //// Call getAuthData after Office.js is ready
                    //getAuthData();
                });
            })
            .catch((error) => {
                console.error("Error loading or initializing Office.js:", error);
            });
    }, []);

    useEffect(() => {

        if (authData.isLoaded) authenticationAction();
    }, [authData]);

    useEffect(() => {
        if (registration) signin();
    }, [registration]);

    useEffect(() => {
        console.log("witway-token", witwayToken);
        if (witwayToken) {
        let token = witwayToken?.witWayToken;
            //Office.onReady(() => {
            //    Office.context.ui.messageParent(JSON.stringify({ token: witwayToken }));
            //});

            axios
                .get(
                    `/account/authentication?sid=${authData.email}&action=${authData.action}`,
                    {
                        headers: {
                            WitWayToken: JSON.stringify(token),
                            witwaySignature: authData.signature
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        setSuccess(true);
                        if (witwayToken)
                            window.Office.context.ui.messageParent(JSON.stringify(witwayToken));
                        //setTimeout(() => {
                        //    window.Office.context.ui.close();
                        //}, 3000);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        window.close();
                    }, 3000);
                });
        }
        
    }, [witwayToken]);

    return (
        <div>
            {isLoading && <Loading />}
            {success && <Successful action={authData.action} />}
            {err && (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    Opp's Something went wrong! Please try again.
                </div>
            )}
        </div>
    );
};

export default PasswordLess;
